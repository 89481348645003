import { Pipe, PipeTransform } from '@angular/core';
import { ClubsService } from '@services/club.service';
import { shareReplay } from 'rxjs';

@Pipe({ name: 'club', standalone: true })
export class ClubPipe implements PipeTransform {
  constructor(private clubService: ClubsService) {}

  transform(clubId: string) {
    return this.clubService.get(clubId).pipe(shareReplay(1));
  }
}
